'use client';
import React, { ElementType } from 'react';
import styles from './Gutter.module.scss';
import clsx from 'clsx';

export type Props = {
    children?: React.ReactNode;
    flex?: boolean;
    gutterSize?: 'extraSmall' | 'small' | 'medium' | 'large' | 'xl' | 'none';
    className?: string;
    as?: ElementType<any>;
    gutterOnMobile?: boolean;
};

export const Gutter: React.FC<Props> = ({
    children,
    flex,
    gutterSize = 'none',
    className,
    as: Component = 'div',
    gutterOnMobile = true,
}: Props): JSX.Element => {
    const classes = clsx(
        styles.gutter,
        !gutterOnMobile && styles['no-gutter'],
        gutterSize !== 'none' && styles[gutterSize],
        className,
    );

    return (
        <Component className={classes} style={{ flex: flex ? 1 : 'initial' }}>
            {children}
        </Component>
    );
};

export default Gutter;
